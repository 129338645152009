import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import Layout from '../components/Layout'

export const WieTemplate = ({
  img,
  fotocredits,
  fotocreditslink,
  wieIntroText,
  wieIntroSub,
  wieIntroMain,
  wieOutroText,
  wieOutroBtn,
}) => {
  return (
    <>
      <section className="home-wie content section color-bg">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10">
              <div className="columns is-variable is-6">
                <div className="column is-4">
                  <div className="portrait">
                    <div className="inner">
                      {img.localFile ? (
                        <Img
                          fluid={img.localFile.childImageSharp.fluid}
                          alt={img.alt_text}
                          title={img.title}
                        />
                      ) : null}
                      {fotocredits ? (
                        <div className="foto-credits">
                          <a
                            href={fotocreditslink}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {fotocredits}
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="column content is-8">
                  <div className="wie-intro">
                    <div dangerouslySetInnerHTML={{ __html: wieIntroText }} />
                    <div
                      className="focus wie"
                      dangerouslySetInnerHTML={{ __html: wieIntroSub }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="content section not ">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-10">
              <div className="columns is-6 is-variable">
                <div className="column is-4" />
                <div className="column is-8">
                  <div
                    className="wie-meer content"
                    dangerouslySetInnerHTML={{ __html: wieIntroMain }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content section has-text-centered mb color-bg">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8 has-text-centered">
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: wieOutroText }}
              />
              <div className="links">
                <Link to="/contact" className="cta-link">
                  {wieOutroBtn}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

WieTemplate.propTypes = {
  img: PropTypes.object,
}

const Wie = ({ data }) => {
  const { wordpressPage: page } = data
  const { title: siteTitle } = data.site.siteMetadata
  const { meta_description: metadesc, meta_tags: metatags } = page.acf

  return (
    <Layout>
      <Helmet>
        <title>{`${page.title} | ${siteTitle}`}</title>
        {metadesc ? <meta name="description" content={metadesc} /> : null}
        {metatags ? <meta name="keywords" content={metatags} /> : null}
      </Helmet>
      <WieTemplate
        img={page.img}
        fotocredits={page.acf.foto_credits}
        fotocreditslink={page.acf.foto_credits_link}
        wieIntroText={page.acf.wie_intro_text}
        wieIntroSub={page.acf.wie_intro_sub}
        wieIntroMain={page.acf.wie_intro_main}
        wieOutroText={page.acf.wie_outro_text}
        wieOutroBtn={page.acf.wie_outro_btn}
      />
    </Layout>
  )
}

Wie.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Wie

export const pageQuery = graphql`
  query WieByID {
    site {
      siteMetadata {
        title
      }
    }
    wordpressPage(wordpress_id: { eq: 2 }) {
      title
      img: featured_media {
        alt_text
        title
        localFile {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        meta_description
        meta_tags
        foto_credits
        foto_credits_link
        wie_intro_text
        wie_intro_sub
        wie_intro_main
        wie_outro_text
        wie_outro_btn
      }
    }
  }
`
